import {BehaviorSubject, Observable, of, tap, throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {API_URL_HEADER, ApiType} from '../http/interceptors/api-end-point.interceptor'
import {ICompany} from './design.types'
import {Injectable} from '@angular/core'
import {colorMap} from '../../pages/user-manager/tab-configuration/configuration.types'
type ColorValue = 'neutral' | 'azure' | 'violet' | 'red' | 'green' | 'blue' | 'magenta' | 'orange' | 'spring-green' | 'chartreuse' | 'cyan' | 'yellow' | 'rose'
@Injectable({
    providedIn: 'root'
})
export class DesignService {
    private readonly DESIGN_STORAGE_KEY = 'app_design_settings'
    private designSettingsSubject = new BehaviorSubject<Partial<ICompany> | null>(null)
    private readonly AVAILABLE_COLORS = colorMap
    public designSettings$ = this.designSettingsSubject.asObservable()
    private currentTheme = new BehaviorSubject<string>('azure');

    private companyPrefixSubject = new BehaviorSubject<string>('');
    private companyIdSubject = new BehaviorSubject<string>('');

    get companyPrefix(): string {
        return this.companyPrefixSubject.getValue()
    }

    get companyId(): string {
        return this.companyIdSubject.getValue()
    }

    constructor(private http: HttpClient) {
    }

    initializeDesignSettings(): Observable<Partial<ICompany>> {
        const cachedSettings = sessionStorage.getItem(this.DESIGN_STORAGE_KEY)

        if (cachedSettings) {
            const settings = JSON.parse(cachedSettings)
            this.designSettingsSubject.next(settings)
            this.applyTheme(settings)
            this.companyIdSubject.next(settings.id)

            // Set company prefix from cached settings
            if (settings.company_prefix) {
                this.companyPrefixSubject.next(settings.company_prefix);
            }

            return of(settings)
        }

        // If no cached settings, fetch from backend
        return this.fetchDesign()
    }

    private fetchDesign(): Observable<Partial<ICompany>> {
        const authzHeaders = new HttpHeaders().set(API_URL_HEADER, ApiType.AUTHZ)

        return this.http.get<ICompany>('companies/design', {
            headers: authzHeaders
        }).pipe(
            tap(settings => {
                sessionStorage.setItem(this.DESIGN_STORAGE_KEY, JSON.stringify(settings))
                this.designSettingsSubject.next(settings)
                this.applyTheme(settings)
                this.companyIdSubject.next(settings.id)

                // Set company prefix from fetched settings
                if (settings.company_prefix) {
                    this.companyPrefixSubject.next(settings.company_prefix)
                }
            }),
            catchError(error => {
                console.error('Failed to fetch design settings:', error)
                return throwError(() => error)
            })
        )
    }

    private applyTheme(settings: Partial<ICompany>) {
        const color = this.AVAILABLE_COLORS[settings.primary_color]
        if (!color) return;

        this.setTheme(color.palette as ColorValue)
    }


    setTheme(theme: ColorValue) {
        const themeClasses = Array.from(document.documentElement.classList).filter(c => c.startsWith('theme-'));

        themeClasses.forEach(c => document.documentElement.classList.remove(c))

        document.documentElement.classList.add(`theme-${theme}`)

        this.currentTheme.next(theme)
    }
}
